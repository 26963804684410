import styled from '@emotion/styled';

export const Bar = styled.div(
    ({theme}) => `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
  opacity: 0.8;
  z-index: 100;
  padding: ${theme.spacings[16]};
  border-top: solid 1px ${theme.colors.grey4};
`,
);
