import React from 'react';
import {usePathname} from 'next/navigation';

import {Bar} from './PreviewBar.styles';
import {H4} from '../../typography';
import {Stack} from '../../layout';
import {ButtonLink} from '../ButtonLink';

export const PreviewBar: React.FC<{
  enabled: boolean;
}> = ({enabled}) => {
  const pathname = usePathname();
  return enabled ? (
    <Bar>
      <Stack items="center" justify="space-between">
        <H4 weight="bold">PREVIEW MODE</H4>
        <ButtonLink
          id="Close Preview"
          href={`/api/exit-preview?path=${pathname}`}
        >
          Close preview
        </ButtonLink>
      </Stack>
    </Bar>
  ) : null;
};
